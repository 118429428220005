import { ClipboardList, Home, Plus } from 'lucide-react';

import { IMenuItem } from 'src/view/layouts/types/IMenuItem';

import { routes } from '../routes';

export function MenuPesquisaSatisfacao(): IMenuItem[] {
  const list: IMenuItem[] = [
    {
      name: 'Página Inicial',
      icon: Home,
      route: routes.HOME,
    },
    {
      name: 'Criar Pesquisa',
      icon: Plus,
      route: routes.PESQUISA_SATISFACAO_CRIAR,
    },
    {
      name: 'Pesquisas',
      icon: ClipboardList,
      route: routes.PESQUISA_SATISFACAO_PRINCIPAL,
    },
  ];

  return list;
}
