import { Navigate, Outlet } from 'react-router-dom';
import { useAuthStore } from 'src/app/store/AuthStore';

import { routes } from './routes';

interface AuthGuardProps {
  isPrivate: boolean
}

export function AuthGuard({ isPrivate }: AuthGuardProps) {
  const signedIn = useAuthStore((store) => store.signedIn);

  if (!signedIn && isPrivate) {
    return <Navigate to={routes.LOGIN} replace />;
  }

  if (signedIn && !isPrivate) {
    return <Navigate to={routes.HOME} replace />;
  }

  return (
    <Outlet />
  );
}
