import { cn } from 'src/app/lib/utils';

interface SpinnerProps {
  size?: number
  className?: string
}

export function Spinner({ size = 32, className }: SpinnerProps) {
  return (
    <div
      className={cn(
        'animate-load z-[105] text-primary',
        className,
      )}
      style={{ fontSize: `${size}px` }}
    />
  );
}
