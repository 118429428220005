import { DtoDiscountRequestDetailsResponse } from 'src/app/types/discountRequest/DtoDiscountRequestDetailsResponse';
import { httpClient } from '../httpClient';

interface IInput {
  cdDesconto?: number
}

export async function detail(input: IInput) {
  const { data } = await httpClient.get<DtoDiscountRequestDetailsResponse>(`api/v1/solicitacaodesconto/${input.cdDesconto}`);

  return data;
}

export const getDetail = (key: string[]) => (params: IInput | undefined) => [
  ...key,
  'detail',
  params?.cdDesconto ?? '',
] as const;
