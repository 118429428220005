import { useEffect, useState } from 'react';
import { Progress } from './ui/progress';

function LinearLoader() {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let subs = true;
    if (progress <= 100) {
      setTimeout(() => {
        if (subs) {
          setProgress(progress + 20);
        }
      }, 1000);
    } else {
      setProgress(0);
    }
    return () => {
      subs = false;
    };
  }, [progress]);

  return (
    <div className="fixed top-0 left-0 z-50 w-full">
      <Progress className="h-1.5" value={progress} />
    </div>
  );
}

export default LinearLoader;
