import { useEffect, useState } from 'react';
import { tenantKey } from '../config/tenantKeys';

export function useTenantKey() {
  const [tenant, setTenant] = useState(() => {
    let location = window.location.host.split('.')[0];
    if (process.env.NODE_ENV === 'development') {
      location = '';
    }
    return tenantKey[location];
  });
  useEffect(() => {
    const location = window.location.host.split('.')[0];
    setTenant(tenantKey[location]);
  }, []);

  return {
    tenant,
    hasTenant: !!tenant,
  };
}
