import { httpClient } from '../httpClient';

interface IInput {
  cdDesconto: number
  cdMensagem: number
  idStatus: 1 | 2
  observacao?: string
  vlDescontoAut?: number
}

export async function update(input: IInput) {
  return httpClient.post('api/v1/solicitacaodesconto', input);
}
