import { startOfMonth, endOfMonth, format } from 'date-fns';

export function formatDateToShort(date: Date | number) {
  return new Intl.DateTimeFormat('pt-br').format(date);
}

export function formatDateComplete(data: string | Date | number) {
  try {
    let dataObj: Date | number = new Date();
    if (typeof data === 'string') {
      dataObj = new Date(data);
    } else {
      dataObj = data;
    }

    const formato = new Intl.DateTimeFormat('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });

    return formato.format(dataObj);
  } catch {
    return data;
  }
}

export function formatDateShort(data: string | Date | number) {
  try {
    let dataObj: Date | number = new Date();
    if (typeof data === 'string') {
      dataObj = new Date(data);
    } else {
      dataObj = data;
    }

    const formato = new Intl.DateTimeFormat('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });

    return formato.format(dataObj);
  } catch {
    return data;
  }
}

export function firstAndLastDateOfMonth(month: string, year: string) {
  const date = new Date(`${year}-${month}`);
  const startDate = startOfMonth(date);
  const endDate = endOfMonth(date);

  return { startDate, endDate };
}

export function firstAndLastDateByMonthAndYear(yyyyMM: string) {
  const date = new Date(yyyyMM);
  const startDate = startOfMonth(date);
  const endDate = endOfMonth(date);

  return { startDate, endDate };
}

export function ToDate(data: string) {
  if (data) {
    const datas = data.split('-');
    const dia = datas[2].split('T');
    return `${dia[0]}/${datas[1]}/${datas[0]}`;
  }
  return data;
}

export function formatDateToIso8601(date: string | number | Date) {
  return format(date, 'yyyy-MM-dd');
}
