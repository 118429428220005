import { useEffect, useState } from 'react';

import { useQuery } from '@tanstack/react-query';

import { useAuthStore } from 'src/app/store/AuthStore';

import { discountRequestService, discountRequestServiceKeys } from 'src/app/services/discountRequestService';

import { DtoMessageDiscountRequest } from 'src/app/types/discountRequest/DtoMessageDiscountRequest';

import { useNotification } from '../useNotification';

export function useGetDiscountRequestMessage() {
  const user = useAuthStore((store) => store.user);

  const [discount, setDiscount] = useState<DtoMessageDiscountRequest | undefined>(undefined);
  const [isOpenModalDiscountRequest, setIsOpenModalDiscountRequest] = useState(false);
  const [selectedDiscount, setSelectedDiscount] = useState<
  DtoMessageDiscountRequest | undefined>(undefined);

  const hasPermission = !!user?.userToken.role.includes('FCAI_0011') || !!user?.userToken.role.includes('SOLICITACAO_DESCONTO');

  const {
    data,
    isFetching,
  } = useQuery({
    queryKey: discountRequestServiceKeys.all,
    queryFn: discountRequestService.get,
    enabled: hasPermission && !isOpenModalDiscountRequest,
    refetchInterval: 12000,
    refetchIntervalInBackground: true,
  });

  const {
    handleShowNotification,
  } = useNotification();

  function handleOpenModalDiscount(discountSelected?: DtoMessageDiscountRequest) {
    setSelectedDiscount(discountSelected);
    setIsOpenModalDiscountRequest(true);
  }

  function handleClosemodalDiscount() {
    setIsOpenModalDiscountRequest(false);
    setSelectedDiscount(undefined);
  }

  useEffect(() => {
    if (data && !isFetching && discount?.cdDesconto !== data.cdDesconto) {
      setDiscount(data);
      handleShowNotification({
        id: data.cdDesconto,
        title: data.deAssunto,
        message: data.deMensagem,
        time: 100000,
        action: {
          label: 'Abrir',
          onClick: () => handleOpenModalDiscount(data),
        },
      });
    }
  }, [data, discount, handleShowNotification, isFetching]);

  return {
    selectedDiscount,
    isOpenModalDiscountRequest,
    handleClosemodalDiscount,
  };
}
