import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { queryClient } from 'src/app/config/queryClient';
import { localStorageKeys } from 'src/app/config/localStorageKeys';

import { UserEntity } from 'src/app/entities/User';

import { httpClient } from 'src/app/services/httpClient';
import { authService } from 'src/app/services/authService';
import { httpClientSettings } from 'src/app/services/Settings/httpClient';

import { dateExpireToken } from 'src/app/utils/dateExpireToken';

interface State {
  user: UserEntity | undefined;
  signedIn: boolean;
}

interface Actions {
  signin: (userEntity: UserEntity) => void
  revalidateTokenUser: () => Promise<string>
  signout: () => void
}

export const useAuthStore = create(persist<State & Actions>((set, get) => ({
  user: undefined,
  signedIn: false,
  signin: (userEntity: UserEntity) => {
    queryClient.clear();

    const {
      dateNow,
      dateExpire,
    } = dateExpireToken(userEntity.userToken.createTime, userEntity.userToken.expires);

    const user = {
      regras: [...userEntity.regras],
      userToken: {
        ...userEntity.userToken,
        createTime: dateNow,
        expires: dateExpire,
      },
    };

    httpClient.defaults.headers.Authorization = `Bearer ${user.userToken.token}`;
    httpClientSettings.defaults.headers.Authorization = `Bearer ${user.userToken.token}`;

    set(() => ({
      user,
      signedIn: true,
    }
    ));
  },
  revalidateTokenUser: async () => {
    try {
      const { user } = get();
      const userToken = user?.userToken?.token;
      if (userToken) {
        const response = await authService.revalidateToken(userToken);
        if (response.status === 200) {
          const {
            dateNow,
            dateExpire,
          } = dateExpireToken(response.data.value.createTime, response.data.value.expires);

          const newUser = {
            regras: [...user.regras],
            userToken: {
              ...user.userToken,
              createTime: dateNow,
              expires: dateExpire,
              token: response.data.value.token,
            },
          };

          set(() => ({
            user: newUser,
            signedIn: true,
          }));

          return newUser.userToken.token;
        }

        set(() => ({
          user: undefined,
          signedIn: false,
        }));
      } else {
        set(() => ({
          user: undefined,
          signedIn: false,
        }));
      }
    } catch (error) {
      set(() => ({
        user: undefined,
        signedIn: false,
      }));

      throw error;
    }

    return '';
  },
  signout: () => {
    set(() => ({
      user: undefined,
      signedIn: false,
    }));
    queryClient.clear();
  },
}), {
  name: localStorageKeys.ACCESS_TOKEN,
}));
