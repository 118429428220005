import { get } from './get';
import { update } from './update';
import { detail, getDetail } from './detail';

export const discountRequestService = {
  get,
  update,
  detail,
};

const all = ['discount'];

export const discountRequestServiceKeys = {
  all,

  detail: getDetail(all),
};
