import { Eye, EyeOff } from 'lucide-react';
import { ComponentProps, forwardRef, useState } from 'react';
import { cn } from 'src/app/lib/utils';
import { HelperText } from '../HelperText';

interface InputProps extends ComponentProps<'input'> {
  name: string;
  errors?: string;
  containerClassName?: string;
  label?: string
  helperText?:string
  hideErrorSize?: boolean
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      placeholder,
      name,
      type,
      id,
      errors,
      className,
      helperText,
      containerClassName,
      hideErrorSize,
      ...props
    },
    ref,
  ) => {
    const inputId = id ?? name;
    const [isEyeOpen, setIsEyeOpen] = useState(true);
    const validType = type === 'password' && !isEyeOpen ? 'text' : type;

    function handleToggleEye() {
      setIsEyeOpen((prevState) => !prevState);
    }

    return (
      <div className={cn('relative', containerClassName)}>
        {!!label && (
          <label
            htmlFor={inputId}
            className="text-xs left-[13px] top-2 pointer-events-none"
          >
            {label}
          </label>
        )}
        <input
          className={cn(
            'flex w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
            type === 'password' && 'pr-10',
            className,
          )}
          {...props}
          ref={ref}
          name={name}
          id={inputId}
          type={validType}
          placeholder={placeholder}
        />
        {type === 'password' && (
          <button
            type="button"
            aria-label="password"
            className={cn(
              'absolute right-[6px] top-7 text-gray-400 rounded-full hover:bg-secondary p-1 transition-colors',
              !label && 'top-1',
            )}
            onClick={handleToggleEye}
          >
            {isEyeOpen && <Eye />}
            {!isEyeOpen && <EyeOff />}
          </button>
        )}
        {helperText && (
          <HelperText type="info" className="mt-1">
            {helperText}
          </HelperText>
        )}
        {(!hideErrorSize || errors) && (
          <HelperText type="error" className="mt-1">
            {errors && (
              errors
            )}
          </HelperText>
        )}
      </div>
    );
  },
);
Input.displayName = 'Input';

export { Input };
