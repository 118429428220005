import { NumericFormat } from 'react-number-format';

import { Modal } from 'src/view/components/Modal';
import { Input } from 'src/view/components/ui/input';
import { Spinner } from 'src/view/components/Spinner';
import { Button } from 'src/view/components/ui/button';
import { Textarea } from 'src/view/components/ui/textarea';

import { formatCurrency } from 'src/app/utils/formatCurrenct';
import { formatDateComplete } from 'src/app/utils/formatDate';

import { DtoMessageDiscountRequest } from 'src/app/types/discountRequest/DtoMessageDiscountRequest';

import { useModalSolicitacaoDescontoController } from './useModalSolicitacaoDescontoController';
import { Loader } from '../../Loader';

interface ModalSolicitacaoDescontoProps {
  open: boolean;
  onClose(): void;
  discount: DtoMessageDiscountRequest;
}

export function ModalSolicitacaoDesconto({
  open,
  onClose,
  discount,
}: ModalSolicitacaoDescontoProps) {
  const {
    data,
    errors,
    isError,
    isPending,
    isFetching,
    observacao,
    valorDesconto,
    refetch,
    handleCancel,
    handleSubmit,
    setObservacao,
    setValorDesconto,
  } = useModalSolicitacaoDescontoController(discount, onClose);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={discount.deAssunto}
      contentClassName="w-[90%] max-w-[600px]"
      isLock
    >
      <Loader isLoading={isPending} />
      {isFetching && (
        <div className="flex items-center justify-center p-6">
          <Spinner size={56} />
        </div>
      )}
      {isError && !isFetching && (
        <div className="flex flex-col items-center gap-2">
          <span className="text-destructive">
            Ocorreu um erro, tente novamente mais tarde
          </span>
          <Button variant="destructive" onClick={() => refetch()}>
            Tentar novamente
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Fechar
          </Button>
        </div>
      )}
      {(!isError && !isFetching && data && !!data.idUsuAutorizacao) && (
        <div className="flex flex-col items-center gap-2">
          <span className="text-destructive">
            Já informado pelo usuário
            {' '}
            {data.idUsuAutorizacao}
          </span>
          <Button variant="ghost" onClick={onClose}>
            Fechar
          </Button>
        </div>
      )}
      {(!isError && !isFetching && data && !data.idUsuAutorizacao) && (
        <>
          <div className="flex overflow-auto min-h-[100px] p-2">
            <Textarea
              className="w-full"
              cols={1}
              maxLength={500}
              rows={5}
              value={discount.deMensagem}
              readOnly
            />
          </div>
          <div className="flex p-2 gap-2 flex-wrap">
            <Input
              name="requisicao"
              label="Requisição:"
              placeholder="Requisição"
              value={data?.postoRequisicao}
              containerClassName="flex-1 min-w-[200px]"
              readOnly
              hideErrorSize
            />
            <Input
              name="paciente"
              label="Paciente:"
              placeholder="Paciente"
              value={data?.nmPacienteGeral}
              containerClassName="flex-[2] min-w-[200px]"
              readOnly
              hideErrorSize
            />
          </div>
          <div className="flex p-2 gap-2 flex-wrap">
            <Input
              name="valorRequisicao"
              label="Valor Requisição:"
              placeholder="Valor Requisição"
              value={formatCurrency(data?.vlApagar)}
              containerClassName="flex-[3] min-w-[150px]"
              readOnly
              hideErrorSize
            />
            <Input
              name="valorDescontoPermitido"
              label="Valor Desconto Permitido:"
              placeholder="Valor Desconto Permitido"
              value={formatCurrency(data?.vlDescontoMax)}
              containerClassName="flex-[3] min-w-[150px]"
              readOnly
              hideErrorSize
            />
            <Input
              name="valorDescontoPermitidoPorcentagem"
              label="%"
              value={`${data?.peMaximoDesconto} %`}
              containerClassName="flex-1 min-w-[80px]"
              readOnly
              hideErrorSize
            />
          </div>
          <div className="flex flex-col p-2 gap-2 flex-wrap">
            <NumericFormat
              thousandSeparator="."
              decimalSeparator=","
              customInput={Input}
              prefix="R$ "
              decimalScale={2}
              fixedDecimalScale
              name="valorDescontoConcedido"
              label="Valor Desconto Concedido:"
              placeholder="Valor Desconto Concedido"
              containerClassName="flex-1 min-w-[200px]"
              value={valorDesconto}
              onValueChange={(e) => setValorDesconto(e.floatValue)}
              errors={errors?.valorDesconto}
            />
            <Input
              name="observacao"
              label="Observação:"
              placeholder="Observação"
              containerClassName="flex-1 min-w-[200px]"
              value={observacao}
              onChange={(e) => setObservacao(e.target.value)}
              errors={errors?.observacao}
            />
          </div>
          <div className="flex p-2 gap-2 flex-wrap">
            <Input
              name="enviadoPor"
              label="Enviado Por:"
              placeholder="Enviado Por"
              value={discount.idUsuInc}
              containerClassName="flex-1 min-w-[200px]"
              readOnly
              hideErrorSize
            />
            <Input
              name="datEnvio"
              label="Em:"
              placeholder="Data Envio"
              value={
                discount.dtIncInf
                  ? (formatDateComplete(discount.dtIncInf) as string)
                  : '-'
              }
              containerClassName="flex-[2] min-w-[200px]"
              readOnly
              hideErrorSize
            />
          </div>
          <div className="p-4 mb-2 flex justify-end gap-2">
            <Button variant="destructive" onClick={handleCancel}>
              Cancelar Desconto
            </Button>
            <Button onClick={handleSubmit}>Conceder Desconto</Button>
          </div>
        </>
      )}
    </Modal>
  );
}
