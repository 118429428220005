import { Transition } from '@headlessui/react';

import { Spinner } from './Spinner';
import { ReactPortal } from './ReactPortal';

interface LoaderProps {
  isLoading: boolean
}

export function Loader({ isLoading }: LoaderProps) {
  if (!isLoading) return null;

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    <ReactPortal containerId="loader-root">
      <Transition
        show={isLoading}
        enter="transition-opacity ease-linear duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-linear duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="h-full w-full fixed inset-0 flex items-center justify-center fade-in-100 z-[100] bg-black/20 backdrop-blur-sm pointer-events-auto"
      >
        <Spinner size={72} className="text-primary" />
      </Transition>
    </ReactPortal>
  );
}
