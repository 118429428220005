import { signin } from './signin';
import { revalidateToken } from './revalidateToken';
import { signinToken } from './loginToken';
import { signinWithAccessToken } from './signinWithAccessToken';

export const authService = {
  signin,
  revalidateToken,
  signinToken,
  signinWithAccessToken,
};

export const authServiceKey = {
  all: ['auth'] as const,

  revalidateToken: (user: string | undefined) => [...authServiceKey.all, user, 'revalidate'],
};
