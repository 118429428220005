import { lazy } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { AlertDialogProvider } from 'src/view/components/Modals/AlertDialogProvider';
import PageLoadable from 'src/view/components/PageLoadable';

import { useInterceptor } from 'src/app/hooks/useInteceptor';
import { useNotification } from 'src/app/hooks/useNotification';

import { queryClient } from 'src/app/config/queryClient';

import { Analytics } from './Analycs';
import { AuthGuard } from './AuthGuard';
import { PrivateRoute } from './PrivateRoute';
import { routes } from './routes';

import { MenuApi } from './Apis/menu-api';
import { MenuPesquisaSatisfacao } from './PesquisaSatisfacao/menu-pesquisa-satisfacao';

const AuthLayout = PageLoadable(
  lazy(() => import('src/view/layouts/AuthLayout')),
);
const Login = PageLoadable(lazy(() => import('src/view/pages/Login')));

const NavBarLayout = PageLoadable(
  lazy(() => import('src/view/layouts/NavBarLayout')),
);
const Home = PageLoadable(lazy(() => import('src/view/pages/Home')));

// Módulos Pesquisa de satisfação
const PesquisaSatisfacao = PageLoadable(
  lazy(() => import('src/view/pages/PesquisaSatisfacao')),
);
const CriarPesquisaSatisfacao = PageLoadable(
  lazy(
    () => import('src/view/pages/PesquisaSatisfacao/CriarPesquisaSatisfacao'),
  ),
);

// Módulos APIs
const Api = PageLoadable(lazy(() => import('src/view/pages/Api')));

export function Router() {
  useInterceptor();
  useNotification();

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <AlertDialogProvider>
        <BrowserRouter basename={process.env.REACT_APP_BASE_ROUTE}>
          <Routes>
            <Route element={<Analytics />}>
              <Route element={<AuthGuard isPrivate={false} />}>
                <Route element={<AuthLayout />}>
                  <Route path={routes.LOGIN} element={<Login />} />
                </Route>
              </Route>
              <Route element={<AuthGuard isPrivate />}>
                <Route element={<PrivateRoute />}>
                  <Route element={<NavBarLayout />}>
                    <Route path="" element={<Home />} />
                  </Route>
                  <Route
                    path=""
                    element={(
                      <NavBarLayout
                        hasSideBar
                        menuItens={MenuPesquisaSatisfacao()}
                      />
                    )}
                  >
                    <Route
                      path={routes.PESQUISA_SATISFACAO_PRINCIPAL}
                      element={<PesquisaSatisfacao />}
                    />
                    <Route
                      path={routes.PESQUISA_SATISFACAO_CRIAR}
                      element={<CriarPesquisaSatisfacao />}
                    />
                  </Route>
                  <Route
                    path=""
                    element={<NavBarLayout hasSideBar menuItens={MenuApi()} />}
                  >
                    <Route path={routes.APLICATIVOS_PRINCIPAL} element={<Api />} />
                  </Route>
                </Route>
              </Route>
            </Route>
          </Routes>
        </BrowserRouter>
      </AlertDialogProvider>
    </QueryClientProvider>
  );
}
