import * as RdxDialog from '@radix-ui/react-dialog';

import { X } from 'lucide-react';
import { useState } from 'react';

import { cn } from 'src/app/lib/utils';

interface ModalProps {
  open: boolean;
  title: string;
  titleClassName?: string;
  rightAction?: React.ReactNode;
  children: React.ReactNode;
  onClose?(): void;
  contentClassName?: string
  overlayClassName?: string
  isLock?: boolean
}

export function Modal({
  open,
  title,
  titleClassName,
  rightAction,
  children,
  onClose,
  contentClassName,
  overlayClassName,
  isLock,
}: ModalProps) {
  const [animationLock, setAnimationLock] = useState('');
  function handleClose() {
    if (!isLock) {
      onClose?.();
    } else {
      setAnimationLock('scale-105 duration-300');
      setTimeout(() => setAnimationLock(''), 300);
    }
  }

  return (
    <RdxDialog.Root open={open} onOpenChange={handleClose}>
      <RdxDialog.Portal>
        <RdxDialog.Overlay
          className={cn(
            'fixed inset-0 z-50 bg-foreground/60 backdrop-blur-sm',
            'data-[state=open]:animate-overlayShow',
            overlayClassName,
          )}
        />
        <RdxDialog.Content
          className={cn(
            'fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-6 space-y-10 bg-background rounded-2xl z-50 outline-none max-h-[90%] overflow-y-auto',
            'shadow-[0px_11px_20px_0px_rgba(0,0,0,0.10)]',
            'data-[state=open]:animate-contentShow',
            animationLock,
            contentClassName,
          )}
        >
          <header className="flex justify-between items-center text-foreground space-x-4">
            <span className={cn(
              'text-xl font-bold',
              titleClassName,
            )}
            >
              {title}
            </span>
            <div className="flex items-center justify-center">
              {rightAction}
              <button
                type="button"
                aria-label="Close Icon"
                className="w-8 h-8 flex items-center justify-center outline-none hover:bg-secondary rounded-full disabled:cursor-not-allowed text-foreground"
                onClick={handleClose}
                disabled={isLock}
              >
                <X className="w-5 h-5" />
              </button>
            </div>
          </header>

          <div>{children}</div>
        </RdxDialog.Content>
      </RdxDialog.Portal>
    </RdxDialog.Root>
  );
}
