/* eslint-disable @typescript-eslint/no-explicit-any */
import { httpClient } from '../httpClient';

export interface RefreshToken {
  value: Value
  formatters: any[]
  contentTypes: any[]
  declaredType: any
  statusCode: any
}

export interface Value {
  token: string
  refreshToken: string
  createTime: Date
  expires: Date
}

export async function revalidateToken(token: string) {
  const result = await httpClient.post<RefreshToken>('v1/login/resultados/refresh_valid_token', null, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return result;
}
