import { Share2, Home } from 'lucide-react';

import { routes } from 'src/router/routes';

import { IMenuItem } from 'src/view/layouts/types/IMenuItem';

export function MenuApi(): IMenuItem[] {
  const list: IMenuItem[] = [
    {
      name: 'Página Inicial',
      icon: Home,
      route: routes.HOME,
    },
    {
      name: 'Aplicativos',
      icon: Share2,
      route: routes.APLICATIVOS_PRINCIPAL,
    },
  ];

  return list;
}
