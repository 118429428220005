import { httpClient } from '../httpClient';

interface SiginParams {
  userName: string
  password: string
  tennant: string
}

interface SiginResponse {
  userToken: UserToken
  regras: Regra[]
}

export interface UserToken {
  userId: string
  userName: string
  role: string[]
  connectionDb: string
  token: string
  codigoResultado: string
  expires: Date
  createTime: Date
}

export interface Regra {
  tipo: string
  valor: string
}

export async function signin({ userName, password, tennant }: SiginParams) {
  const { data } = await httpClient.post<SiginResponse>('v1/login/enterprise', {
    userName,
    password,
  }, {
    headers: {
      codBanco: tennant,
    },
  });

  return data;
}
