import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { logEvent } from 'firebase/analytics';
import { firebaseService } from 'src/app/services/firebaseService';

export function Analytics() {
  const location = useLocation();

  useEffect(() => {
    logEvent(firebaseService.analytics, 'page_view', {
      firebase_screen: location.pathname + location.hash + location.search,
      firebase_screen_class: 'firebase-routes-analytics', // <- This name is up to you
      page_location: JSON.stringify(location),
      page_path: location.pathname + location.hash + location.search,
      page_title: location.pathname,
    });
  }, [location]);

  return <Outlet />;
}
