import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getPerformance } from 'firebase/performance';

const config = {
  apiKey: 'AIzaSyB9OBsrr6XzPs0LyExZCgFwVmLvawlLfN4',
  authDomain: 'bitlab-manager.firebaseapp.com',
  projectId: 'bitlab-manager',
  storageBucket: 'bitlab-manager.appspot.com',
  messagingSenderId: '605426094582',
  appId: '1:605426094582:web:5a9ec53ac06a8bd85bb7d7',
  measurementId: 'G-NZZBJF7WPE',
};
const firebaseApp = initializeApp(config);

const analytics = getAnalytics(firebaseApp);

const performance = getPerformance(firebaseApp);

export const firebaseService = {
  firebaseApp,
  analytics,
  performance,
};
