import * as React from 'react';
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogFooter,
} from 'src/view/components/ui/alert-dialog';
import { Input } from 'src/view/components/ui/input';
import { Button } from 'src/view/components/ui/button';
import { cn } from 'src/app/lib/utils';

export const AlertDialogContext = React.createContext<
  <T extends AlertAction>(
params: T
) => Promise<T['type'] extends 'alert' | 'danger' | 'confirm' ? boolean : null | string>
>(() => null!,
  );

export type AlertAction =
  | { type: 'alert'; title: string; body?: string; cancelButton?: string }
  | { type: 'danger'; title: string; body?: string; cancelButton?: string; actionButton?: string; }
  | {
    type: 'confirm';
    title: string;
    body?: string;
    cancelButton?: string;
    actionButton?: string;
  }
  | {
    type: 'prompt';
    title: string;
    body?: string;
    cancelButton?: string;
    actionButton?: string;
    defaultValue?: string;
    inputProps?: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
    >;
  }
  | { type: 'close' };

interface AlertDialogState {
  open: boolean;
  title: string;
  body: string;
  type: 'alert' | 'confirm' | 'prompt' | 'danger';
  cancelButton: string;
  actionButton: string;
  defaultValue?: string;
  inputProps?: React.PropsWithoutRef<
  React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
  >
  >;
}

export function alertDialogReducer(
  state: AlertDialogState,
  action: AlertAction,
): AlertDialogState {
  switch (action.type) {
    case 'close':
      return { ...state, open: false };
    case 'danger':
    case 'alert':
    case 'confirm':
    case 'prompt':
      return {
        ...state,
        open: true,
        ...action,
        cancelButton:
          // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
          action.cancelButton || (action.type === 'alert' ? 'Ok' : 'Cancelar'),
        actionButton:
          // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
          ('actionButton' in action && action.actionButton) || 'Ok',
      };
    default:
      return state;
  }
}

export function AlertDialogProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [state, dispatch] = React.useReducer(alertDialogReducer, {
    open: false,
    title: '',
    body: '',
    type: 'alert',
    cancelButton: 'Cancelar',
    actionButton: 'Confirmar',
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const resolveRef = React.useRef<(tf: any) => void>();

  function close() {
    dispatch({ type: 'close' });
    resolveRef.current?.(false);
  }

  function confirm(value?: string) {
    dispatch({ type: 'close' });
    resolveRef.current?.(value ?? true);
  }

  const dialog = React.useCallback(async <T extends AlertAction>(params: T) => {
    dispatch(params);

    return new Promise<
    T['type'] extends 'alert' | 'confirm' | 'danger' ? boolean : null | string
    >((resolve) => {
      resolveRef.current = resolve;
    });
  }, []);

  return (
    <AlertDialogContext.Provider value={dialog}>
      {children}
      <AlertDialog
        open={state.open}
        onOpenChange={(open) => {
          if (!open) close();
        }}
      >
        <AlertDialogContent asChild>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              // eslint-disable-next-line max-len
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
              confirm(event.currentTarget.prompt?.value);
            }}
          >
            <AlertDialogHeader>
              <AlertDialogTitle className={cn(
                state.type === 'danger' && 'text-error',
              )}
              >
                {state.title}

              </AlertDialogTitle>
              {state.body ? (
                <AlertDialogDescription>{state.body}</AlertDialogDescription>
              ) : null}
            </AlertDialogHeader>
            {state.type === 'prompt' && (
              <Input
                name="prompt"
                defaultValue={state.defaultValue}
                {...state.inputProps}
              />
            )}
            <AlertDialogFooter>
              {state.type === 'alert' ? null : (
                <Button type="button" variant="ghost" onClick={close}>
                  {state.cancelButton}
                </Button>
              )}
              <Button type="submit" variant={state.type === 'danger' ? 'destructive' : 'default'}>{state.actionButton}</Button>
            </AlertDialogFooter>
          </form>
        </AlertDialogContent>
      </AlertDialog>
    </AlertDialogContext.Provider>
  );
}
type Params<T extends 'danger' | 'alert' | 'confirm' | 'prompt'> =
  | Omit<Extract<AlertAction, { type: T }>, 'type'>
  | string;

export function useConfirm() {
  const dialog = React.useContext(AlertDialogContext);

  return React.useCallback(
    (params: Params<'confirm'>) => dialog({
      ...(typeof params === 'string' ? { title: params } : params),
      type: 'confirm',
    }),
    [dialog],
  );
}
export function usePrompt() {
  const dialog = React.useContext(AlertDialogContext);

  return (params: Params<'prompt'>) => dialog({
    ...(typeof params === 'string' ? { title: params } : params),
    type: 'prompt',
  });
}
export function useAlert() {
  const dialog = React.useContext(AlertDialogContext);
  return (params: Params<'alert'>) => dialog({
    ...(typeof params === 'string' ? { title: params } : params),
    type: 'alert',
  });
}
export function useDanger() {
  const dialog = React.useContext(AlertDialogContext);
  return (params: Params<'danger'>) => dialog({
    ...(typeof params === 'string' ? { title: params } : params),
    type: 'danger',
  });
}
