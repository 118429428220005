import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import { useGetDiscountRequestDetails } from 'src/app/hooks/SolicitacaoDesconto/useGetDiscountRequestDetails';
import { discountRequestService } from 'src/app/services/discountRequestService';
import { errorService } from 'src/app/services/errorService';

import { DtoMessageDiscountRequest } from 'src/app/types/discountRequest/DtoMessageDiscountRequest';

interface FormData {
  observacao?: string;
  valorDesconto?: string;
}

export function useModalSolicitacaoDescontoController(
  discount: DtoMessageDiscountRequest,
  onClose: () => void,
) {
  const [valorDesconto, setValorDesconto] = useState<number | undefined>(0);
  const [observacao, setObservacao] = useState('');
  const [errors, setErrors] = useState<FormData | undefined>();

  const {
    data, isFetching, isError, refetch,
  } = useGetDiscountRequestDetails({
    cdDesconto: discount.cdDesconto,
  });

  const { mutateAsync, isPending } = useMutation({
    mutationFn: discountRequestService.update,
  });

  function handleIsValidForm() {
    if (!valorDesconto) {
      setErrors((prevState) => ({
        ...prevState,
        valorDesconto: 'Informe um valor de desconto!',
      }));
      return false;
    }

    if (valorDesconto <= 0) {
      setErrors((prevState) => ({
        ...prevState,
        valorDesconto: 'Valor do desconto deve ser maior que zero!',
      }));
      return false;
    }

    if (data?.vlDesconto && valorDesconto > data.vlDesconto) {
      setErrors((prevState) => ({
        ...prevState,
        valorDesconto: 'Valor do desconto maior que o permitido!',
      }));
      return false;
    }

    return true;
  }

  const handleSubmit = async () => {
    if (!handleIsValidForm() || !data) return;

    try {
      await mutateAsync({
        cdDesconto: data.cdDesconto,
        cdMensagem: discount.cdMensagem,
        idStatus: 1,
        observacao,
        vlDescontoAut: valorDesconto,
      });
    } catch (error) {
      errorService.errorRequest(error, {
        idToast: 'error-discount-request',
      });
    } finally {
      onClose();
    }
  };

  const handleCancel = async () => {
    if (!data) {
      onClose();
      return;
    }

    try {
      await mutateAsync({
        cdDesconto: data.cdDesconto,
        cdMensagem: discount.cdMensagem,
        idStatus: 2,
        observacao,
        vlDescontoAut: data.vlDesconto,
      });
    } catch (error) {
      errorService.errorRequest(error, {
        idToast: 'error-discount-request',
      });
    } finally {
      onClose();
    }
  };

  useEffect(() => {
    if (data) {
      setValorDesconto(data.vlDesconto);
    }
  }, [data]);

  return {
    data,
    errors,
    isError,
    isPending,
    isFetching,
    observacao,
    valorDesconto,
    refetch,
    handleCancel,
    handleSubmit,
    setObservacao,
    setValorDesconto,
  };
}
