import { Suspense } from 'react';
import LinearLoader from './LinearLoader';

const PageLoadable = (Component:
// eslint-disable-next-line func-names, @typescript-eslint/no-explicit-any
React.LazyExoticComponent<(props: any) => JSX.Element>) => function (props: any) {
  return (
    <Suspense fallback={<LinearLoader />}>
      { }
      <Component {...props} />
    </Suspense>
  );
};

export default PageLoadable;
