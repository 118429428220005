import { Outlet } from 'react-router-dom';
import { useGetDiscountRequestMessage } from 'src/app/hooks/SolicitacaoDesconto/useGetDiscountRequestMessage';
import { ModalSolicitacaoDesconto } from 'src/view/components/Modals/ModalSolicitacaoDesconto';

export function PrivateRoute() {
  const {
    selectedDiscount,
    isOpenModalDiscountRequest,
    handleClosemodalDiscount,
  } = useGetDiscountRequestMessage();

  return (
    <>
      <Outlet />
      {selectedDiscount && (
        <ModalSolicitacaoDesconto
          open={isOpenModalDiscountRequest}
          onClose={handleClosemodalDiscount}
          discount={selectedDiscount}
        />
      )}
    </>
  );
}
