import { PropsWithChildren } from 'react';
import { cn } from 'src/app/lib/utils';

interface HelperTextProps {
  className?: string
  type?: 'info' | 'error';
}

export function HelperText({ className, type = 'error', children }: PropsWithChildren<HelperTextProps>) {
  return (
    <span className={cn(
      'block text-xs pt-[2px] pl-2 pb-1 min-h-[25px]',
      type === 'info' && 'text-info',
      type === 'error' && 'text-error',
      className,
    )}
    >
      {children}
    </span>
  );
}
