/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { isAxiosError } from 'axios';

import { toast } from 'react-hot-toast';

import { ApiError } from 'src/app/types/ApiError';

interface ErrorRequestProps {
  messageDefault?: string,
  ms?: number
  idToast?: string
}

function errorRequest(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any,
  {
    messageDefault = 'Ocorreu um erro, tente novamente mais tarde!',
    ms = 7000,
    idToast,
  }: ErrorRequestProps,
) {
  // eslint-disable-next-line no-console
  console.error(
    '🚀 ~ file: ErrorService.js:5 ~ ErrorRequest ~ error:',
    JSON.stringify(error, null, 2),
    error,
  );
  if (isAxiosError<ApiError>(error)) {
    if (error.response && error.response?.status >= 500) {
      toast.error(messageDefault, {
        duration: ms,
        id: idToast,
      });
      return;
    }

    if (
      error?.response?.data?.Errors !== null
      && error?.response?.data?.Errors !== undefined
      && Object.keys(error?.response?.data?.Errors).length > 0
    ) {
      Object.keys(error?.response?.data?.Errors).map(
        (keyError) => (error?.response?.data?.Errors
          // @ts-expect-error
          ? error.response.data.Errors[keyError].forEach(
            (textError: string) => {
              toast.error(textError, {
                duration: ms,
                id: idToast,
              });
            },
          )
          : toast.error(messageDefault, {
            duration: ms,
            id: idToast,
          })),
      );
    } else if (error?.response?.data?.Message) {
      toast.error(error.response?.data?.Message ?? messageDefault, {
        duration: ms,
        id: idToast,
      });
    } else {
      toast.error(messageDefault, {
        duration: ms,
        id: idToast,
      });
    }
  } else {
    toast.error(messageDefault, {
      duration: ms,
      id: idToast,
    });
  }
}

export const errorService = {
  errorRequest,
};
