import { useEffect, useState } from 'react';
import { toast } from 'sonner';

interface IInputNotification {
  title: string,
  message: string,
  time?: number,
  action?: {
    label: string,
    onClick: () => void
  },
  id?: string | number,
}

export function useNotification() {
  const [notificationPermission, setNotificationPermission] = useState<
  NotificationPermission | null>(null);
  const [inBackground, setInBackground] = useState(false);

  function handleUserBlur() {
    setInBackground(true);
  }

  function handleUserFocus() {
    setInBackground(false);
  }

  const checkNotificationPermission = async () => {
    if (!('Notification' in window)) {
      setNotificationPermission('denied');
      return;
    }

    const permission = await Notification.requestPermission();
    setNotificationPermission(permission);
  };

  const handleShowNotification = ({
    id,
    title,
    message,
    action,
    time,
  }: IInputNotification) => {
    if (notificationPermission !== 'granted' || inBackground) {
      // eslint-disable-next-line no-new
      const notification = new Notification(title, {
        body: message,
        icon: '/favicon-white.ico',
        vibrate: 300,
      });

      toast(title, {
        description: message,
        action: action ?? undefined,
        closeButton: true,
        id,
        duration: time,
      });

      notification.addEventListener('click', () => {
        window.focus();
        toast(title, {
          description: message,
          action: action ?? undefined,
          closeButton: true,
          id,
          duration: time,
        });
      });
    } else {
      toast(title, {
        description: message,
        action: action ?? undefined,
        closeButton: true,
        id,
        duration: time,
      });
    }
  };

  useEffect(() => {
    checkNotificationPermission();
  }, []);

  useEffect(() => {
    window.addEventListener('blur', handleUserBlur);

    window.addEventListener('focus', handleUserFocus);

    return () => {
      window.removeEventListener('blur', handleUserBlur);
      window.removeEventListener('focus', handleUserFocus);
    };
  }, []);

  return {
    inBackground,
    notificationPermission,
    handleShowNotification,
  };
}
