import { httpClient } from '../httpClient';

interface ISigninTokenResponse {
  accessToken: string
}

export async function signinToken() {
  const { data } = await httpClient.get<ISigninTokenResponse>('v1/login/enterprise/temporary/token');

  return data;
}
