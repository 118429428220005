import { Toaster } from 'react-hot-toast';

import { Toaster as ToasterSonner } from './view/components/ui/sonner';

import { ThemeProvider } from './app/context/ThemeContext';

import { Router } from './router';

function App() {
  return (
    <ThemeProvider defaultTheme="light" storageKey="vite-ui-theme">
      <Router />
      <Toaster />
      <ToasterSonner />
    </ThemeProvider>
  );
}

export default App;
