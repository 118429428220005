import { httpClient } from '../httpClient';

interface SiginResponse {
  userToken: UserToken
  regras: Regra[]
}

export interface UserToken {
  userId: string
  userName: string
  role: string[]
  connectionDb: string
  token: string
  codigoResultado: string
  expires: Date
  createTime: Date
}

export interface Regra {
  tipo: string
  valor: string
}

export async function signinWithAccessToken(accessToken: string) {
  const { data } = await httpClient.post<SiginResponse>(`v1/login/enterprise/temporary/login?access_token=${accessToken}`);

  return data;
}
