export function dateExpireToken(dateCreateServer: Date, dateExpireServer: Date) {
  const diffDate = new Date(dateExpireServer).getTime() - new Date(dateCreateServer).getTime();

  const dateNow = new Date();
  const dateExpire = new Date(new Date().getTime() + diffDate);

  return {
    dateNow,
    dateExpire,
  };
}
