import { useQuery } from '@tanstack/react-query';

import { discountRequestService, discountRequestServiceKeys } from 'src/app/services/discountRequestService';

interface IInput {
  cdDesconto?: number
}

export function useGetDiscountRequestDetails(input: IInput) {
  const {
    data,
    isFetching,
    isError,
    refetch,
    error,
  } = useQuery({
    queryKey: discountRequestServiceKeys.detail(input),
    queryFn: () => discountRequestService.detail(input),
    enabled: !!input?.cdDesconto,
  });

  return {
    data,
    error,
    isError,
    isFetching,
    refetch,
  };
}
