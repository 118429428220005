import { useEffect } from 'react';

import { AxiosError } from 'axios';

import { useAuthStore } from 'src/app/store/AuthStore';

import { httpClient } from 'src/app/services/httpClient';
import { httpClientPesquisaSatisfacao } from 'src/app/services/PesquisaSatisfacao/httpClient';
import { httpClientSettings } from 'src/app/services/Settings/httpClient';

import { useTenantKey } from './useTenantKey';

export function useInterceptor() {
  const user = useAuthStore((store) => store.user);
  const revalidateTokenUser = useAuthStore(
    (store) => store.revalidateTokenUser,
  );
  const signout = useAuthStore((store) => store.signout);
  const { tenant, hasTenant } = useTenantKey();
  if (hasTenant) {
    httpClient.defaults.baseURL = tenant?.baseUrl;
    httpClientSettings.defaults.baseURL = tenant?.baseUrlSettings;
    httpClientPesquisaSatisfacao.defaults.baseURL = tenant?.baseUrlSatisfacao;
  }

  useEffect(() => {
    httpClient.interceptors.request.use(async (config) => {
      if (config.url?.includes('v1/login/resultados/refresh_valid_token')) {
        return config;
      }
      if (user) {
        const configToken = config;
        const token = user.userToken.token ?? '';
        const expires = user.userToken.expires ?? '';
        const createTime = user.userToken.createTime ?? '';

        if (expires && createTime) {
          const dateNow = new Date();
          // revalidate a cada uma hora
          const dateToValidate = new Date(
            new Date(expires).getTime() - 1 * 60 * 60 * 1000,
          );

          if (dateNow > dateToValidate) {
            const newToken = await revalidateTokenUser();
            configToken.headers.Authorization = `Bearer ${newToken}`;
            // eslint-disable-next-line no-console
            console.log('atualizando token');
            return configToken;
          }
        }

        if (token) {
          configToken.headers.Authorization = `Bearer ${token}`;
        }

        return configToken;
      }
      return config;
    });

    httpClient.interceptors.response.use(
      (response) => response,
      async (error: AxiosError) => {
        if (error?.response?.status === 401) {
          signout();
        }

        return Promise.reject(error);
      },
    );

    httpClientSettings.interceptors.request.use(async (config) => {
      if (user) {
        const configToken = config;
        const token = user.userToken.token ?? '';
        const expires = user.userToken.expires ?? '';
        const createTime = user.userToken.createTime ?? '';

        if (expires && createTime) {
          const dateNow = new Date();
          // revalidate a cada uma hora
          const dateToValidate = new Date(
            new Date(expires).getTime() - 1 * 60 * 60 * 1000,
          );

          if (dateNow > dateToValidate) {
            const newToken = await revalidateTokenUser();
            configToken.headers.Authorization = `Bearer ${newToken}`;
            // eslint-disable-next-line no-console
            console.log('atualizando token');
            return configToken;
          }
        }

        if (token) {
          configToken.headers.Authorization = `Bearer ${token}`;
        }

        return configToken;
      }
      return config;
    });

    httpClientSettings.interceptors.response.use(
      (response) => response,
      async (error: AxiosError) => {
        if (error?.response?.status === 401) {
          signout();
        }

        return Promise.reject(error);
      },
    );

    httpClientPesquisaSatisfacao.interceptors.request.use(async (config) => {
      if (config.url?.includes('v1/login/resultados/refresh_valid_token')) {
        return config;
      }
      if (user) {
        const configToken = config;
        const token = user.userToken.token ?? '';
        const expires = user.userToken.expires ?? '';
        const createTime = user.userToken.createTime ?? '';

        if (expires && createTime) {
          const dateNow = new Date();
          // revalidate a cada uma hora
          const dateToValidate = new Date(
            new Date(expires).getTime() - 1 * 60 * 60 * 1000,
          );

          if (dateNow > dateToValidate) {
            const newToken = await revalidateTokenUser();
            configToken.headers.Authorization = `Bearer ${newToken}`;
            // eslint-disable-next-line no-console
            console.log('atualizando token');
            return configToken;
          }
        }

        if (token) {
          configToken.headers.Authorization = `Bearer ${token}`;
        }

        return configToken;
      }
      return config;
    });

    httpClientPesquisaSatisfacao.interceptors.response.use(
      (response) => response,
      async (error: AxiosError) => {
        if (error?.response?.status === 401) {
          signout();
        }

        return Promise.reject(error);
      },
    );

    if (!user?.userToken.connectionDb) {
      httpClient.interceptors.request.clear();
      httpClient.interceptors.response.clear();
      httpClientSettings.interceptors.request.clear();
      httpClientSettings.interceptors.response.clear();
      httpClientPesquisaSatisfacao.interceptors.request.clear();
      httpClientPesquisaSatisfacao.interceptors.response.clear();
    }

    return () => {
      httpClient.interceptors.request.clear();
      httpClient.interceptors.response.clear();
      httpClientSettings.interceptors.request.clear();
      httpClientSettings.interceptors.response.clear();
      httpClientPesquisaSatisfacao.interceptors.request.clear();
      httpClientPesquisaSatisfacao.interceptors.response.clear();
    };
  }, [revalidateTokenUser, signout, user]);
}
